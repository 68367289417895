.videosection-container {
	background-image: linear-gradient(
		#000,
		rgb(36, 36, 36),
		rgb(36, 36, 36),
		#000
	);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	object-fit: contain;
	margin: 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.videosectionflex1 {
	display: flex;
	margin-left: 4rem;
	margin-right: 2rem;
	flex-grow: 1;
}

.videosectionflex2 {
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
}

.fineprint {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 0.5rem;
	font-weight: 200;
	align-items: left;
	justify-content: left;
	text-align: left;
	margin: 0;
	margin-top: 4rem;
	color: #fff;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	/* margin-top: 0.5rem; */
}

.troma-logo {
	display: block;
	position: relative;
	width: 400px;
	margin-bottom: 1rem;
	z-index: 0;
}

.vid-car {
	display: flex;
	flex-grow: 1;
}

.vid-car-mobile {
	display: none;
}

.videosection-container h1 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-style: italic;
	font-size: 1.4rem;
	line-height: 1.75rem;
	font-weight: 900;
	margin: 0;
	color: white;
	margin-bottom: 0.5rem;
}

.videosection-container p {
	display: block;
	letter-spacing: 0.1rem;
	margin: 0;
	padding: 0;
	margin-bottom: 0.1rem;
	color: #fff;
}

.about-textbox {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 350px;
	margin: 1rem;
}

.videos {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
}

.videosection-container .player-wrapper {
	width: 50vw;
	margin: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.videosection-container .carousel-container {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	/* transform: translateY(-1.8rem); */
}

.video-title {
	display: block;
	color: white;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1rem;
	font-weight: 900;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.claimed {
	background: linear-gradient(90deg, #ff8a00, #ff3300);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
}

.videosection-container a:hover {
	color: #000;
}

@media screen and (max-width: 1200px) {
	/* .player-wrapper {
		width: 50vw;
		margin: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	} */

	.videos li {
		padding: 1rem;
	}

	.video-title {
		display: block;
		color: white;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-size: 0.8rem;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		margin-left: 0;
		margin-right: 0;
	}

	.video-title-2 {
		display: block;
		color: white;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-size: 0.8rem;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: 0rem;
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	/* .vjs-big-play-button {
		height: 14.5rem !important;
	} */
}

.about-bigbutton-2 {
	display: flex;
	justify-content: center;
	color: #000;
	background: linear-gradient(90deg, #ffffff, #ffffff);
	border-style: none;
	align-items: center;
	cursor: pointer;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	transition: all 0.2s ease;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	text-decoration: none;
	margin-right: 0;
	width: 325px;
	max-width: 75vw;

	height: 50px;
	z-index: 799;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	transition: 0.7s;
	margin: 1rem;
}

.about-bigbutton-2:hover {
	font-size: 0.8rem;
	transition: 0.7s;
}

@media screen and (max-width: 850px) {
	.about-bigbutton-2 {
		display: none;
	}

	.videosection-container {
		flex-direction: column;
	}

	.videos {
		align-items: flex-end;
	}

	.videosectionflex1 {
		margin-left: 0rem;
		margin-right: 0rem;
		width: 80%;
		flex-grow: 1;
	}

	.videosectionflex2 {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		margin: 0;
		margin-top: 0rem;
		flex-grow: 1;
	}

	.about-textbox {
		width: 36vw;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.videosection-container p {
		font-size: 0.8rem;
	}
	.vidcar {
		height: auto;
	}
}

@media screen and (max-width: 675px) {
	.videosection-container .carousel-container {
		height: auto;
	}

	.videosectionflex1 {
		width: 80%;
		justify-content: flex-end;
	}
	.videosectionflex2 {
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 0rem;
	}

	.about-textbox {
		width: 75vw;
		margin: 0;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
}

@media screen and (max-height: 500px) {
	.videosectionflex1 {
		display: flex;
		margin-left: 4rem;
		margin-right: 2rem;
		flex-grow: 1;
		width: 40%;
	}

	.videosectionflex2 {
		display: flex;
		flex-direction: column;
		margin-right: 2rem;
	}

	.videosection-container {
		flex-direction: row;
	}
}
