.hero-container {
	background-image: linear-gradient(
		rgba(255, 0, 0, 0),
		rgba(255, 0, 0, 0),
		rgba(0, 0, 0, 1)
	);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
	margin: 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.heroflexes {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.heroflex1 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	height: 100vh;
	order: 1;
}

.heroflex2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 100vh;
	order: 2;
}

.taicon {
	width: 300px;
	z-index: 799;
	/* transform: translate(-2rem, -2rem); */
	padding-right: 2rem;
	padding-bottom: 2rem;
	/* transform-origin: 2rem; */
	cursor: pointer;
	transition: 0.2s ease-in-out;
}

.taicon:hover {
	transform: scale(1.025);
}

.herotextbox {
	display: flex;
	flex-direction: column;
	z-index: 1;
	padding-left: 2rem;
	padding-bottom: 2rem;
	justify-content: flex-start;
	align-items: flex-start;
}

.hero-container h1 {
	display: flex;
	align-items: flex-start;
	font-style: italic;
	font-size: 1.4rem;
	line-height: 2rem;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-weight: 800;
	z-index: 1;
	margin-bottom: 0.25rem;
}

.hero-container h3 {
	display: block;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #fff;
	/* text-transform: uppercase; */
	letter-spacing: 0.1rem;
	font-weight: 500;
	margin: 0;
	z-index: 1;
	width: min-content;
	min-width: 100%;
}

.hero-container video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	transform: translateY(42px); /* shifted down for navbar (no effect version) */
	z-index: -1;
}

.MobileBG {
	display: none;
	max-width: 100vw;
}

.header1 {
	display: block;
	max-width: 75vw;
	width: 60rem;
	height: auto;
	padding-bottom: 5rem;
	z-index: 799;
	overflow: auto;
}

.button-container {
	display: flex;
	max-width: 75vw;
	width: 575px;
	--tw-backdrop-blur: blur(64px);
	backdrop-filter: var(--tw-backdrop-filter);
	--tw-bg-opacity: 0.2;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	--tw-border-opacity: 0.1;
	border-color: rgba(255, 255, 255, var(--tw-border-opacity));
	border-style: solid;
	border-width: 0.75px;
	border-radius: 0.25rem;
	align-items: center;
	justify-content: center;
	height: 5rem;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	z-index: 799;
}

.hero-btns {
	padding-left: 1rem;
	padding-right: 1rem;
	z-index: 799;
}

.hero-btns .btn {
	margin: 0;
	z-index: 799;
}
.bigbutton {
	display: flex;
	justify-content: center;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
	border-style: none;
	align-items: center;
	cursor: pointer;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	transition: all 0.2s ease;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	max-width: 180px;
	text-decoration: none;
	width: 200px;
	height: 50px;
	z-index: 799;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	transition: 0.7s;
}

.bigbutton:hover {
	color: #fff;
	font-size: 0.8rem;
	transition: 0.7s;
}

.hero-container .nav-replacement {
	width: 100%;
	top: 0;
	position: fixed;
	z-index: 1099;
}

.hero-container .burger-replacement {
	display: none;
	width: 100%;
	max-width: 100vw;
	top: 0;
	position: fixed;
	z-index: 1099;
}

@media screen and (max-width: 1100px) {
	.button-container {
		width: 500px;
	}
}

@media screen and (max-width: 960px) {
	.hero-container h1 {
		font-size: 1.5rem;
	}

	.hero-container {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
	}

	.heroflexes {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	.heroflex1 {
		order: 2;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0.5rem;
		padding-bottom: 2rem;
		height: auto;
	}

	.heroflex2 {
		order: 1;
		padding-bottom: 0.5rem;
	}

	.taicon {
		padding: 0;
		width: 550px;
		max-width: 50vw;
	}

	.herotextbox {
		padding: 0;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 80vw;
	}

	.hero-container .burger-replacement {
		display: flex;
		z-index: 1099;
	}

	.hero-container .nav-replacement {
		display: none;
		padding: 0;
	}
}

@media screen and (max-width: 500px) {
	.taicon {
		padding: 0;
		width: 550px;
		max-width: 85vw;
	}

	.hero-container video {
		display: none;
	}

	.MobileBG {
		display: block;
		object-fit: cover;
		filter: brightness(55%);
		width: 100vw;
		grid-column-start: 1;
		grid-row-start: 1;
		transform: var(--tw-transform);
		height: 100vh;
		position: fixed;
		z-index: -1;
	}

	.hero-btns {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	.button-container {
		width: 350px;
	}

	.bigbutton {
		width: 120px;
		font-size: 0.65rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.bigbuttontext {
		display: block;
		font-size: 0.5rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	.heroflex1 {
		order: 2;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0.5rem;
		padding-bottom: 5rem;
		height: auto;
	}
}

@media screen and (max-height: 500px) {
	.taicon {
		width: 300px;
		z-index: 799;
		/* transform: translate(-2rem, -2rem); */
		/* transform-origin: 2rem; */
		cursor: pointer;
		transition: 0.2s ease-in-out;
	}

	.taicon:hover {
		transform: scale(1.025);
	}

	.herotextbox {
		display: flex;
		flex-direction: column;
		z-index: 1;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.hero-container h1 {
		display: flex;
		align-items: flex-start;
		font-style: italic;
		font-size: 1rem;
		line-height: 2rem;
		color: #fff;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		font-weight: 800;
		z-index: 1;
		margin-bottom: 0.1rem;
	}

	.hero-container h3 {
		display: block;
		font-size: 0.9rem;
		line-height: 1.5rem;
		color: #fff;
		/* text-transform: uppercase; */
		letter-spacing: 0.1rem;
		font-weight: 500;
		margin: 0;
		z-index: 1;
		width: min-content;
		min-width: 100%;
	}

	.header1 {
		display: block;
		max-width: 75vw;
		width: 60rem;
		height: auto;
		padding-bottom: 5rem;
		z-index: 799;
		overflow: auto;
	}

	.button-container {
		display: flex;
		max-width: 75vw;
		width: 575px;
		--tw-backdrop-blur: blur(64px);
		backdrop-filter: var(--tw-backdrop-filter);
		--tw-bg-opacity: 0.2;
		background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
		--tw-border-opacity: 0.1;
		border-color: rgba(255, 255, 255, var(--tw-border-opacity));
		border-style: solid;
		border-width: 0.75px;
		border-radius: 0.25rem;
		align-items: center;
		justify-content: center;
		height: 5rem;
		box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
		z-index: 799;
	}

	.hero-btns {
		padding-left: 1rem;
		padding-right: 1rem;
		z-index: 799;
	}

	.hero-btns .btn {
		margin: 0;
		z-index: 799;
	}
	.bigbutton {
		display: flex;
		justify-content: center;
		--tw-text-opacity: 1;
		color: rgba(255, 255, 255, var(--tw-text-opacity));
		--tw-bg-opacity: 1;
		background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
		border-style: none;
		align-items: center;
		cursor: pointer;
		transform: var(--tw-transform);
		position: relative;
		border: 0;
		overflow: hidden;
		outline: none;
		transition: all 0.2s ease;
		box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
		max-width: 180px;
		text-decoration: none;
		width: 200px;
		height: 50px;
		z-index: 799;
		font-size: 0.75rem;
		font-weight: 600;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		transition: 0.7s;
	}

	.bigbutton:hover {
		color: #fff;
		font-size: 0.8rem;
		transition: 0.7s;
	}

	.hero-container video {
		display: none;
	}

	.MobileBG {
		display: block;
		object-fit: cover;
		filter: brightness(55%);
		width: 100vw;
		grid-column-start: 1;
		grid-row-start: 1;
		transform: var(--tw-transform);
		height: 100vh;
		position: fixed;
		z-index: -1;
	}
}
