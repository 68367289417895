.redfooter-container {
	background-color: #0596f9;
	padding: 1rem 0 1rem 0;
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
}

.redfooter-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.whitelogo {
	max-width: 10rem;
}

.website-rights {
	color: #fff;
	padding-top: 0.5rem;
	padding-bottom: 0.35rem;
	font-size: 0.56rem;
	letter-spacing: 0.05em;
}

@media screen and (max-width: 500px) {
	.whitelogo {
		max-width: 30vw;
	}

	.website-rights {
		font-size: 0.33rem;
	}
}
