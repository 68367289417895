@import url("https://fonts.cdnfonts.com/css/avenir-next-cyr");

* {
	-ms-overflow-style: none;
}

::-webkit-scrollbar {
	display: none;
}

body {
	font-family: "Avenir Next", "Avenir Next Cyr", Helvetica, sans-serif;
	margin: 0;
	font-size: 18px;
}

.shine-button {
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	color: #fff;
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease;
}

.shine-button:after {
	content: "";
	position: absolute;
	top: 0;
	left: -200%;
	width: 200%;
	height: 100%;
	transform: skewX(-20deg);
	background-image: linear-gradient(
		90deg,
		transparent,
		hsla(0, 0%, 100%, 0.1),
		transparent
	);
	-webkit-animation: shine 1.9s ease infinite;
	animation: shine 1.9s ease infinite;
}

.shine-button-white {
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	color: rgb(0, 0, 0);
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease;
}

.shine-button-white:after {
	content: "";
	position: absolute;
	top: 0;
	left: -200%;
	width: 200%;
	height: 100%;
	transform: skewX(-20deg);
	background-image: linear-gradient(
		90deg,
		transparent,
		hsla(0, 0%, 0%, 0.638),
		s transparent
	);
	-webkit-animation: shine 1.9s ease infinite;
	animation: shine 1.9s ease infinite;
}

@-webkit-keyframes shine {
	to {
		left: 200%;
	}
}

@keyframes shine {
	to {
		left: 200%;
	}
}

.carousel-container .video-js {
	color: #000 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.video-js {
	color: #000 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.vjs-big-play-button {
	border: none !important;
	background: none !important;
	font-size: 3.6rem !important;
	color: rgba(255, 255, 255, 0.5) !important;
	top: 40% !important;
	left: 64% !important;
	margin-top: -0.75em !important;
	margin-left: -1.5em !important;
}

.vjs-volume-level,
.vjs-play-progress {
	background: linear-gradient(90deg, #0096ff, #ffd200, #ff3300) !important;
}

.vjs-picture-in-picture-control {
	display: none !important;
}

.vjs-control-bar {
	/* background: linear-gradient(
		90deg,
		rgba(0, 149, 255, 0.5),
		rgba(255, 208, 0, 0.5),
		rgba(255, 51, 0, 0.5)
	) !important; */
	background-color: rgba(255, 255, 255, 1) !important;
	border-radius: 5px !important;
	width: 98% !important;
	left: auto !important;
	right: auto !important;
	bottom: 2px !important;
}

.vjs-poster {
	background-color: #dacfb5 !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}

.availability {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 0.8rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	color: white;
	margin-top: 0.5rem;
}

.availability-1 {
	background: linear-gradient(90deg, #0096ff, #80be80);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
}

.availability-1-spc {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-style: italic;
	font-size: 1.5rem;
	font-weight: 900;
	align-items: left;
	justify-content: left;
	text-align: left;
	margin: 0;
	background: linear-gradient(90deg, #80be80, #ffd200);
	background-clip: text;
	color: transparent;
	background-size: 70%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin-top: 0.5rem;
}

.availability-1-spc2 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-style: italic;
	font-size: 1.4rem;
	line-height: 1.75rem;
	font-weight: 900;
	align-items: left;
	justify-content: left;
	text-align: left;
	margin: 0;
	background: linear-gradient(90deg, #ff8a00, #ff3300);
	background-clip: text;
	color: white;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin-bottom: 0.5rem;
}

.availability-1-spc-mini {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-style: italic;
	font-size: 0.7rem;
	font-weight: 900;
	align-items: left;
	justify-content: left;
	text-align: left;
	margin: 0;
	background: linear-gradient(90deg, #0096ff, #80be80);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	/* margin-top: 0.5rem; */
}

.availability-2 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	background: linear-gradient(90deg, #80be80, #ffd200);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin-top: 0.5rem;
}

.availability-3 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	background: linear-gradient(90deg, #ffd200, #ff8a00);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin-top: 0.5rem;
}

.availability-4 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	background: linear-gradient(90deg, #ff8a00, #ff3300);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin-top: 0.5rem;
}

.availability-5 {
	color: black;
}

.app-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 5rem;
	font-weight: 800;
	margin: 0;
	max-width: 75vw;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}
