.inventory-container {
	background-image: radial-gradient(
		closest-side at 50% 50%,
		#f9f4f4,
		rgba(255, 255, 255, 0.428) 100% 100%
	);
	height: auto;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	object-fit: contain;
	margin: 0;
}

.inventory-container .nav-replacement {
	width: 100%;
	top: 0;
	position: fixed;
	z-index: 1099;
}

.inventory-container .burger-replacement {
	display: none;
	width: 100%;
	max-width: 100vw;
	top: 0;
	position: fixed;
	z-index: 1099;
}

.inventory-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0.5rem;
}

.inventory-claimed {
	display: block;
	color: #000;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 0.9rem;
	font-weight: 900;
	font-style: italic;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 0.6rem;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.inventory-video-title {
	display: block;
	color: #000;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1.1rem;
	font-weight: 900;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 0rem;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.inventory-videos {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	/* max-width: 75vw; */
	/* width: 1000px; */
	/* margin: 1.5rem; */
	/* margin-right: 9rem; */
	justify-content: center;
	align-items: center;
}

.inventory-player-wrapper {
	width: 50vw;
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.inventory-videos ul {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding-left: 0;
	margin-top: 2rem;
}

.inventory-videos li {
	display: flex;
	justify-content: center;
	align-items: center;
}

.inventory-flex {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* margin-bottom: 2rem; */
	width: auto;
}

.inventory-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 2rem;
}

.inventory-logo {
	border-bottom-width: 1px;
	justify-content: center;
	align-items: center;
	width: 10vw;
	height: auto;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	position: sticky;
	padding-top: 2rem;
	z-index: 1199;
	-webkit-transition: all ease-out 0.25s;
	-moz-transition: all ease-out 0.25s;
	-o-transition: all ease-out 0.25s;
	transition: all ease-out 0.25s;
}

.inventory-about {
	display: block;
	color: black;
	margin-top: 3rem;
	/* margin-bottom: 1rem; */
	text-align: center;
}

.inventory-about h1 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 3rem;
	/* font-style: italic; */
	font-weight: 900;
	background: linear-gradient(90deg, #ff8a00, #ff3300);
	background-clip: text;
	color: transparent;
	background-size: 100%;
	--webkit-background-clip: text;
	--moz-background-clip: text;
	--webkit-text-fill-color: transparent;
	--moz-text-fill-color: transparent;
	margin: 0;
	/* margin-left: 5rem; */
	/* text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%); */
}

.inventory-hero-btn {
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	flex-direction: row;
	z-index: 799;
}

.inventory-hero-btns .btn {
	margin: 0;
	z-index: 799;
}

.inventory-bigbutton {
	display: flex;
	justify-content: center;
	--tw-text-opacity: 1;
	color: #fff;
	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
	border-style: none;
	align-items: center;
	cursor: pointer;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	transition: all 0.2s ease;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	text-decoration: none;
	width: 300px;
	height: 50px;
	z-index: 799;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	transition: 0.7s;
	margin-top: 1rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.inventory-bigbutton:hover {
	font-size: 0.8rem;
	transition: 0.7s;
	color: #000;
}

.inventory-bigbutton-2 {
	display: flex;
	justify-content: center;
	color: black;
	background-image: linear-gradient(90deg, #0096ff, #ffd200, #f83500);
	border-style: none;
	align-items: center;
	cursor: pointer;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	transition: all 0.2s ease;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* max-width: 180px; */
	text-decoration: none;
	width: 240px;
	height: 50px;
	z-index: 799;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	transition: 0.7s;
	margin-top: 1rem;
	margin-bottom: 5rem;
}

.inventory-bigbutton-2:hover {
	font-size: 0.8rem;
	transition: 0.7s;
}

@media screen and (max-width: 1200px) {
	.inventory-about h1 {
		font-size: 1.3rem;
		margin: 0;
	}
}

@media screen and (max-width: 960px) {
	/* .inventory-container .burger-replacement {
		display: flex;
		z-index: 1099;
	} */
	/* 
	.inventory-container .nav-replacement {
		display: none;
		padding: 0;
	} */

	.inventory-flex {
		flex-direction: column;
	}

	.inventory-right {
		padding-left: 0;
	}

	.inventory-videos ul {
		margin-top: 4.5rem;
	}

	.inventory-videos li {
		margin-bottom: 2rem;
	}

	.inventory-player-wrapper {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.inventory-bigbutton {
		width: 50vw;
	}
}

@media screen and (max-width: 500px) {
	.inventory-logo {
		width: 60vw;
	}
	.inventory-container {
		flex-direction: column;
		height: auto;
	}

	.inventory-about {
		margin: 0;
		margin-top: 5rem;
		margin-bottom: 1.5rem;
		text-align: center;
	}

	.inventory-about h1 {
		font-size: 2.5rem;
		margin: 0;
	}

	.inventory-video-title {
		font-size: 0.8rem;
		margin-top: 0.5rem;
	}

	.inventory-bigbutton {
		margin-bottom: 1.5rem;
		width: 75vw;
	}

	.inventory-player-wrapper {
		width: 75vw;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.inventory-right {
		padding-left: 0rem;
	}
}
