.burger-bar {
	--tw-border-opacity: 0;
	border-bottom-width: 1px;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	padding-top: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: sticky;
	z-index: 1199;
	-webkit-transition: all ease-out 0.5s;
	-moz-transition: all ease-out 0.5s;
	-o-transition: all ease-out 0.5s;
	transition: all ease-out 0.5s;
	border: none;
	/* below this - remove effect */
	--tw-background-opacity: 1;
	background-color: #000;
	padding-top: 0.03rem;
	padding-bottom: 0.2rem;
}

/* .burger-bar.colorChange {
	--tw-background-opacity: 1;
	background-color: #000;
	padding-top: 0.03rem;
	padding-bottom: 0.2rem;
	transition: all ease-out 0.5s;
} */

/* pushes logo to the left */
.burger-bar .logo-spacer {
	flex-grow: 1;
	z-index: 1299;
}

.burger-bar .logo-container {
	display: block;
	position: relative;
	--tw-border-opacity: 0;
	z-index: 1299;
}

/* Replace with svg */
/* img tag */
.burgerbar-logo {
	padding: 0.1rem;
	height: 1.75rem;
	width: auto;
	max-width: 100%;
	display: flex;
	cursor: pointer;
	z-index: 1299;
}

/* Contains both the hamburger and the hamburger menu */
.bar-container {
	display: flex;
	align-items: center;
}

/* Contains the hamburger */
.hamburger-container {
	display: block;
	position: relative;
	z-index: 1299;
}

.hamburger {
	color: #fff;
	z-index: 1299;
}

/* Contains the hamburger menu */
.menu-container {
	position: fixed;
	top: 0;
	left: 100%;
	width: 100vw;
	height: 100vh;
	display: block;
	background-color: rgba(0, 0, 0);
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 5rem;
	transition: 0.5s ease-in-out;
	box-sizing: border-box;
	border: 0 solid;
}

/* Tells the hamburger menu to appear */
.menu-container.active {
	left: 0;
	transition: all 0.5s ease;
	opacity: 1;
	z-index: 998;
}

.hamburger-menu {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.75rem;
	letter-spacing: 0.1rem;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-top: 15px;
	box-sizing: border-box;
	border: 0 solid;
}

.burger-item {
	box-sizing: border-box;
	border: 0 solid;
	--tw-border-opacity: 0.1;
	border-color: rgba(255, 255, 255, var(--tw-border-opacity));
	border-bottom-width: 1px;
	border-top-width: 1px;
	display: flex;
}

.burger-links {
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	align-items: center;
	width: 100%;
	display: flex;
	position: relative;
	text-decoration: inherit;
	cursor: pointer;
}

.social-icon-h {
	stroke: #fff;
	fill: #fff;
	width: 1rem;
	height: 1rem;
	display: block;
	padding-right: 0.5rem;
	/* margin-left: auto; */
}
