.faq-container {
	background-image: linear-gradient(#94c36b, #0596f9);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	object-fit: contain;
}

.faq-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 5rem;
	font-weight: 800;
	margin: 0;
	max-width: 75vw;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.faq-link {
	text-decoration: none;
	color: rgb(104, 104, 249);
}
.drop-down {
	padding-top: 1rem;
	text-transform: none;
	color: #767676;
}

.faq-container p {
	display: block;
	max-width: 75vw;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

details {
	display: block;
	background: #fff;
	padding: 1rem;
	padding-left: 1.5rem;
	width: 25rem;
	max-width: 75vw;
	font-size: 1rem;
	margin-bottom: 1.2rem;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	line-height: 1.5rem;
	cursor: pointer;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

details summary {
	list-style: none;
	max-width: 75vw;
}

details summary::-webkit-details-marker {
	display: none;
}

.tos-link {
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-size: 1rem;
}

.tos-link:hover {
	color: white;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
}

@media screen and (max-width: 500px) {
	.faq-header {
		font-size: 4.5rem;
	}

	details {
		width: 62.5vw;
		font-size: 0.9rem;
	}
}
