.product-container {
	background-image: linear-gradient(#000, #f83500);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	object-fit: contain;
	margin: 0;
}

.product-img-container {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;
	margin-right: 5rem;
}

.product-text-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.img {
	display: block;
	width: 35vw;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.img2 {
	display: block;
	width: 10vw;
	align-items: center;
	justify-content: center;
	margin: 0;
	transform: translateY(0rem);
}

.product-text-container h2 {
	display: block;
	width: 30vw;
	margin: 0;
	text-transform: uppercase;
	font-style: italic;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-weight: 900;
	font-size: 1.4rem;
	color: white;
	letter-spacing: 0.1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.product-text-container p {
	display: block;
	margin: 0;
	width: 30vw;
	flex-direction: column;
	font-size: 1rem;
	text-align: left;
	line-height: 1.5rem;
	text-align: left;
	color: white;
	letter-spacing: 0.1rem;
	/* margin-top: 0.5rem; */
	margin-bottom: 1rem;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.product-text-container ul {
	margin: 0;
	flex-direction: column;
	color: white;
	letter-spacing: 0.1rem;
	padding-left: 1rem;
	width: 30vw;
	/* margin-top: 0.5rem; */
	margin-bottom: 0.7rem;
	font-style: italic;
}

.product-text-container li {
	display: list-item;
	list-style: disc outside;
	font-size: 1rem;
	flex-direction: column;
	color: white;
	letter-spacing: 0.1rem;
	line-height: 1.5rem;
	padding-bottom: 0.2rem;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.product-bigbutton {
	display: flex;
	justify-content: center;
	color: #fff;
	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
	border-style: none;
	align-items: center;
	cursor: pointer;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	transition: all 0.2s ease;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	max-width: 180px;
	text-decoration: none;
	margin-right: 1rem;
	width: 200px;
	height: 50px;
	z-index: 799;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	transition: 0.7s;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.product-bigbutton:hover {
	color: #fff;
	font-size: 0.8rem;
	transition: 0.7s;
}

@media screen and (max-width: 1000px) {
	.product-container {
		flex-direction: column;
	}

	.img {
		width: 35vw;
		margin-bottom: 1rem;
	}

	.img2 {
		display: block;
		width: 15vw;
		margin-bottom: 1rem;
	}

	.product-img-container {
		margin-right: 0rem;
	}

	.product-text-container h2 {
		display: none;
		width: 45vw;
		margin-top: 1.5rem;
		font-size: 1.4rem;
		text-align: left;
		transform: translateX(0rem);
	}

	.product-text-container p {
		width: 45vw;
		line-height: 1.5rem;
		font-size: 0.9rem;
		transform: translateX(0rem);
	}

	.product-text-container h3 {
		width: 75vw;
		line-height: 1.5rem;
		font-size: 0.9rem;
		transform: translateX(0rem);
	}

	.product-text-container ul {
		width: 45vw;
		font-size: 0.9rem;
		line-height: 1.5rem;
		transform: translateX(0rem);
	}

	.product-text-container li {
		width: 45vw;
		font-size: 0.9rem;
		line-height: 1.5rem;
		transform: translateX(0rem);
	}

	.product-bigbutton {
		margin-top: 1rem;
	}

	.product-text-container {
		align-items: center;
	}
}

@media screen and (max-width: 500px) {
	.img {
		width: 75vw;
		margin-bottom: 1rem;
	}

	.img2 {
		display: block;
		width: 150px;
		/* margin-bottom: 1rem; */
		transform: translateY(1rem);
	}

	.product-text-container p {
		width: 75vw;
		line-height: 1.2rem;
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
		transform: translateX(0rem);
		text-align: left;
	}

	.product-text-container ul {
		width: 75vw;
		line-height: 1.2rem;
		margin-bottom: 0.5rem;
		transform: translateX(0rem);
		text-align: left;
	}

	.product-text-container li {
		width: 75vw;
		font-size: 0.8rem;
		line-height: 1.3rem;
		padding-bottom: 0.5rem;
		transform: translateX(0rem);
	}
}

@media screen and (max-height: 500px) {
	.product-container {
		flex-direction: row;
	}

	.product-img-container {
		display: flex;
		flex-direction: column;
		position: relative;
		align-items: center;
		justify-content: center;
		margin-right: 2rem;
		margin-left: 2rem;
	}

	.product-text-container {
		display: flex;
		flex-direction: column;
		position: relative;
		margin-right: 2rem;
		align-items: flex-start;
		justify-content: center;
	}

	.img {
		display: block;
		width: 35vw;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	.img2 {
		display: block;
		width: 10vw;
		align-items: center;
		justify-content: center;
		margin: 0;
		transform: translateY(0rem);
	}

	.product-text-container h2 {
		display: block;
		width: auto;
		margin: 0;
		text-transform: uppercase;
		font-style: italic;
		font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
		font-weight: 900;
		font-size: 1rem;
		color: white;
		letter-spacing: 0.1rem;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.product-text-container p {
		display: block;
		margin: 0;
		width: auto;
		flex-direction: column;
		font-size: 0.7rem;
		text-align: left;
		line-height: 1.2rem;
		text-align: left;
		color: white;
		letter-spacing: 0.1rem;
		/* margin-top: 0.5rem; */
		margin-bottom: 0.5rem;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.product-text-container ul {
		margin: 0;
		flex-direction: column;
		color: white;
		letter-spacing: 0.1rem;
		padding-left: 1rem;
		width: 30vw;
		/* margin-top: 0.5rem; */
		margin-bottom: 0.7rem;
		font-style: italic;
	}

	.product-text-container li {
		display: list-item;
		list-style: disc outside;
		font-size: 1rem;
		flex-direction: column;
		color: white;
		letter-spacing: 0.1rem;
		line-height: 1.5rem;
		padding-bottom: 0.2rem;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.product-bigbutton {
		display: flex;
		justify-content: center;
		color: #fff;
		--tw-bg-opacity: 1;
		background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
		border-style: none;
		align-items: center;
		cursor: pointer;
		transform: var(--tw-transform);
		position: relative;
		border: 0;
		overflow: hidden;
		outline: none;
		transition: all 0.2s ease;
		box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
		max-width: 180px;
		text-decoration: none;
		margin-right: 1rem;
		width: 200px;
		height: 50px;
		z-index: 799;
		font-size: 0.75rem;
		font-weight: 600;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		transition: 0.7s;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.product-bigbutton:hover {
		color: #fff;
		font-size: 0.8rem;
		transition: 0.7s;
	}
}
