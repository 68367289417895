.onboarding-container {
	background-image: radial-gradient(
		closest-side at 50% 50%,
		#570000,
		rgb(0, 0, 0) 100% 100%
	);
	height: auto;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
	margin: 0;
}

.fulfill-altlink {
	display: block;
	color: white;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 10px;
	line-height: 1.5rem;
	margin-top: 0.2rem;
	text-align: center;
	text-decoration: underline white;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.framelogo {
	height: 2rem;
	justify-content: center;
	align-items: center;
}

.onboarding-precontainer {
	display: flex;
	flex-direction: column;
	width: 100vw;
	align-items: center;
	justify-content: center;
}

.onboarding-container .social-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
}

.onboarding-container .bigbutton {
	padding-left: 1rem;
	padding-right: 1rem;
	width: 400px;
	max-width: none;
	margin-top: 0.5rem;
}

.onboarding-logo {
	border-bottom-width: 1px;
	justify-content: center;
	align-items: center;
	width: 10vw;
	height: auto;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	position: sticky;
	/* padding-top: 2rem; */
	z-index: 1199;
}

.onboarding-subcontainer-1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	/* margin-top: 2rem; */
	/* margin-bottom: 0.5rem; */
	max-width: 400px;
}

.onboarding-subcontainer-3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 400px;
}

.onboarding-subcontainer-1 h1,
h2,
h3,
p {
	display: block;
	color: white;
	letter-spacing: 0.1rem;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.onboarding-subcontainer-1 h1 {
	font-weight: 900;
	text-transform: uppercase;
	font-size: 4rem;
	margin-bottom: 0;
	padding-bottom: 0;
}

.onboarding-subcontainer-1 h2 {
	font-weight: 900;
	text-transform: uppercase;
	font-size: 2rem;
	margin-bottom: 0.5rem;
}

.onboarding-subcontainer-3 h2 {
	font-weight: 900;
	text-transform: uppercase;
	font-size: 2rem;
	margin-bottom: 0.5rem;
}

.onboarding-subcontainer-1 h3 {
	font-weight: 900;
	text-transform: uppercase;
	font-size: 1.72rem;
	/* margin-bottom: 0.5rem; */
}

.onboarding-subcontainer-1 p {
	font-weight: 400;
	font-size: 0.9rem;
	line-height: 1.5rem;
	margin-bottom: 0.5rem;
}

.onboarding-subcontainer-2 {
	display: flex;
	flex-direction: column;
	margin: 0;
	/* margin-left: 5rem; */
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
	max-width: 75vw;
}

.onboarding-subcontainer-3 p {
	font-weight: 400;
	font-size: 0.9rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
	text-align: center;
}

.onboarding-content {
	display: flex;
	flex-direction: column;
	width: 40vw;
	justify-content: center;
}

.onboarding-container .nav-replacement {
	width: 100%;
	top: 0;
	position: fixed;
	z-index: 1099;
}

.whitelogo {
	max-width: 10rem;
}

.text-diminished {
	color: rgb(191, 191, 191);
}

.onboarding-container .website-rights {
	color: #fff;
	padding-top: 0.5rem;
	padding-bottom: 0.35rem;
	font-size: 0.56rem;
	letter-spacing: 0.05em;
}

.onboarding-bottom-text {
	margin-top: 1rem;
}

.onboarding-top-text {
	margin-bottom: 1rem;
}

.onboarding-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-weight: 800;
	font-size: 1.55rem;
	/* padding: 6px 12px; */
	padding-bottom: 6px;
	max-width: 90vw;
	margin: 0;
}

.secreticon {
	display: block;
	width: 300px;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.onboarding-form-control {
	margin-bottom: 20px;
	justify-content: center;
	align-items: center;
}

.onboarding-container input[type="name"],
input[type="email"],
input[type="message"] {
	display: block;
	width: 400px;
	height: 40px;
	padding: 6px 12px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #000;
	background-color: #fff;
	background-image: none;
	border: 0;
	letter-spacing: 0.1rem;
	box-sizing: border-box;
	max-width: 75vw;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* margin-bottom: 20px; */
	--webkit-border-radius: 0 !important;
	--webkit-appearance: none !important;
	border-radius: 0 !important;
	justify-content: center;
	align-items: center;
	margin-right: 0;
}

@media screen and (max-width: 500px) {
	.onboarding-logo {
		border-bottom-width: 1px;
		justify-content: center;
		align-items: center;
		width: 50vw;
		height: auto;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		position: sticky;
		padding-top: 10rem;
		z-index: 1199;
		-webkit-transition: all ease-out 0.25s;
		-moz-transition: all ease-out 0.25s;
		-o-transition: all ease-out 0.25s;
		transition: all ease-out 0.25s;
	}

	.onboarding-subcontainer {
		display: flex;
		flex-direction: row;
		margin-top: 2rem;
		margin-bottom: 5rem;
	}

	.onboarding-subcontainer-1 {
		max-width: 75vw;
	}

	.onboarding-subcontainer-1 h1 {
		font-size: 2.9rem;
	}

	.onboarding-subcontainer-1 h2 {
		font-size: 1.45rem;
	}

	.onboarding-subcontainer-1 p {
		font-size: 0.75rem;
	}

	.onboarding-img-container {
		display: none;
	}

	.onboarding-content {
		margin-top: 0rem;
		margin-bottom: 4rem;
		width: 75vw;
	}

	.whitelogo {
		max-width: 30vw;
	}

	.website-rights {
		font-size: 0.33rem;
	}
}

@media screen and (max-height: 500px) {
	.header1 {
		width: 60vw;
		transform: translateY(3rem);
	}

	.button-container {
		width: 26rem;
	}
}
