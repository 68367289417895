.carousel-container .carousel {
	width: 100%;
	height: auto;
	margin: auto;
}

.carousel-container .carousel-inner {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.carousel-container .carousel-item {
	/* padding-right: 2rem;
	padding-left: 2rem; */
	margin-bottom: 2rem;
}

.carousel-container .carousel-control-prev {
	transform: translate(-4rem, -1rem) !important;
	display: none;
}
.carousel-container .carousel-control-next {
	transform: translate(4rem, -1rem) !important;
	display: none;
}

.carousel-container .carousel-caption {
	transform: translateY(4.4rem) !important;
	right: 1% !important;
	left: 1% !important;
	padding-top: 0;
}

.carousel-container .carousel-indicators {
	transform: translateY(2.5rem);
}

.carousel-container .video-js {
	color: #000 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

/* .carousel-container .vjs-big-play-button {
	border: none !important;
	background: none !important;
	font-size: 5rem !important;
	color: #fff !important;
	top: 50% !important;
	left: 50% !important;
	margin-top: -0.75em !important;
	margin-left: -1.5em !important;
} */

.carousel-container .vjs-volume-level,
.vjs-play-progress {
	background: linear-gradient(90deg, #0096ff, #ffd200, #ff3300) !important;
}

.carousel-container .vjs-picture-in-picture-control {
	display: none !important;
}

.carousel-container .vjs-control-bar {
	/* background: linear-gradient(
		90deg,
		rgba(0, 149, 255, 0.5),
		rgba(255, 208, 0, 0.5),
		rgba(255, 51, 0, 0.5)
	) !important; */
	background-color: rgba(255, 255, 255, 1) !important;
	border-radius: 5px !important;
	width: 98% !important;
	left: auto !important;
	right: auto !important;
	bottom: 2px !important;
}

@media screen and (max-width: 1200px) {
	/* .carousel-container .carousel {
		width: 400px;
	} */
	/* .carousel-container .carousel-control-prev {
		transform: translate(-3rem, -1rem) !important;
	}
	.carousel-container .carousel-control-next {
		transform: translate(3rem, -1rem) !important;
	} */

	/* .vjs-big-play-button {
		line-height: 0.9em !important;
	} */
}

/* @media screen and (max-width: 500px) {
	.carousel-container .carousel {
		width: 335px;
	}

	.carousel-container .carousel-inner {
		margin-top: 1rem;
	}
	.carousel-container .carousel-caption {
		transform: translateY(4.6rem) !important;
		right: 15%;
		left: 15%;
	}

	.carousel-container .carousel-control-prev {
		visibility: hidden;
	}
	.carousel-container .carousel-control-next {
		visibility: hidden;
	}
} */
