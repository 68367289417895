.contact-container {
	background-image: linear-gradient(#94c36b, #0596f9);
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	object-fit: contain;
}

.contact-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-weight: 800;
	font-size: 3.7rem;
	padding: 6px 12px;
	max-width: 90vw;
	margin: 0;
}

.contact-container input[type="name"],
input[type="email"] {
	display: block;
	width: 400px;
	height: 40px;
	padding: 6px 12px;
	font-size: 12px;
	line-height: 1.42857143;
	color: #000;
	background-color: #fff;
	background-image: none;
	border: 0;
	letter-spacing: 0.1rem;
	box-sizing: border-box;
	max-width: 75vw;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* margin-bottom: 20px; */
	--webkit-border-radius: 0 !important;
	--webkit-appearance: none !important;
	border-radius: 0 !important;
}

textarea {
	display: block;
	width: 400px;
	height: 80px;
	padding: 6px 12px;
	font-size: 12px;
	font-family: Helvetica, sans-serif;
	line-height: 1.42857143;
	color: #000;
	background-color: #fff;
	background-image: none;
	border: 0;
	letter-spacing: 0.1rem;
	box-sizing: border-box;
	max-width: 75vw;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* margin-bottom: 20px; */
	resize: none;
	--webkit-border-radius: 0 !important;
	--webkit-appearance: none !important;
	border-radius: 0 !important;
}

input:focus {
	outline: none !important;
}

textarea:focus {
	outline: none !important;
}

.form-control {
	margin-bottom: 20px;
}

::placeholder {
	text-transform: uppercase;
	/* padding-top: 0.5rem; */
	/* padding-bottom: 0.5rem; */
	/* font-size: 0.56rem; */
	letter-spacing: 0.1rem;
	font-size: 12px;
}

textarea::placeholder {
	/* transform: translateY(4px); */
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	font-family: Helvetica, sans-serif;
	font-size: 12px;
}

.error {
	color: red;
}

.contact-button {
	display: flex;
	justify-content: center;
	border-style: none;
	align-items: center;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	cursor: pointer;
	font-size: 0.75rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	transition: 0.7s;
	margin-bottom: 0.5rem;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);

	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));

	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));

	width: 400px;
	height: 40px;
	max-width: 75vw;

	z-index: 799;
}

.contact-button:hover {
	color: #fff;
	font-size: 0.8rem;
	transition: 0.7s;
}

.contact-button:disabled {
	--tw-bg-opacity: 1;
	background-color: rgba(192, 192, 192, var(--tw-bg-opacity));
}

@media screen and (max-width: 500px) {
	.contact-header {
		font-size: 2.65rem;
	}
}
