.roadmap-container {
	background-image: linear-gradient(#ffd100, #94c36b);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	object-fit: contain;
}

.roadmap-subcontainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.roadmap-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 3.96rem;
	font-weight: 800;
	margin: 0;
	margin-top: 2rem;
	max-width: 75vw;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.roadmap-container h3 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 1.8rem;
	font-weight: 800;
	margin: 0;
	max-width: 75vw;
	text-shadow: none;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.roadmap-container h2 {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 1rem;
	font-weight: 800;
	margin: 0;
	max-width: 75vw;
	text-shadow: none;
	margin-bottom: 10px;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.roadmap-container p {
	display: block;
	/* text-transform: uppercase; */
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 1rem;
	font-weight: 500;
	margin: 0;
	max-width: 75vw;
	/* margin-bottom: 10px; */
}

.roadmap-body {
	display: flex;
	flex-direction: row;
}

.roadmap-cell {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	/* width: 300px;
	margin: 20px; */
	/* background-color: #000; */
	margin-left: 3.5em;
	margin-right: 3.5rem;
	margin-bottom: 3rem;
	margin-top: 2rem;
	width: 220px;
}

.roadmap-cell-spc {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	/* width: 300px;
	margin: 20px; */
	/* background-color: #000; */
	margin-left: 3.5em;
	margin-right: 3.5rem;
	margin-bottom: 3rem;
	margin-top: 2rem;
	width: 220px;
}

.arrow-cell {
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeline {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0rem;
}

.status {
	padding: 0px 40px;
	display: flex;
	justify-content: center;
	border-top: 10px solid #fff;
	position: relative;
}

.tl-item-left {
	font-weight: 600;
	width: 15vw;
}

.tl-item-left:before {
	content: "";
	width: 25px;
	height: 25px;
	color: white;
	background-color: white;
	border-radius: 25px;
	position: absolute;
	top: -17px;
	left: -5%;
}

.tl-item-right {
	font-weight: 600;
	width: 15vw;
}

.tl-item-right:before {
	content: "";
	width: 25px;
	height: 25px;
	color: white;
	background-color: white;
	border-radius: 25px;
	position: absolute;
	top: -17px;
	left: 95%;
}

.tl-item-center {
	font-weight: 600;
	width: 15vw;
}
.tl-item-center:before {
	content: "";
	width: 25px;
	height: 25px;
	color: white;
	background-color: white;
	border-radius: 25px;
	position: absolute;
	top: -17px;
	left: 45%;
}

/* the first bar */
.li.complete .status {
	/* border-style: solid;
	border-width: 2px; */
	border-top: 10px solid black;
	border-image: linear-gradient(90deg, #0096ff, #94c36b 30%, #fff, #fff) 1;
}

/* the first dot */
.li.complete .status :before {
	background-color: #0096ff;
	border: none;
}

.roadmap-2container {
	display: flex;
	flex-direction: column;
}

.timeline2 {
	display: none;
}

@media screen and (max-width: 1000px) {
	.timeline {
		display: none;
	}

	.roadmap-header {
		font-size: 3rem;
	}

	.roadmap-body {
		flex-direction: column;
		transform: translateY(-6px);
	}

	.roadmap-2container {
		flex-direction: row;
	}

	.timeline2 {
		list-style-type: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-left: 0rem;
	}

	.status {
		padding: 75px 0px;
		display: flex;
		justify-content: center;
		border-right: 10px solid #fff;
		border-top: none;
		position: relative;
	}

	.tl-item-left {
		font-weight: 600;
		width: 0vw;
	}

	.tl-item-left:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: -10%;
		left: -7.5px;
	}

	.tl-item-right {
		font-weight: 600;
		width: 0vw;
	}

	.tl-item-right:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: 95%;
		left: -7.5px;
	}

	.tl-item-center {
		font-weight: 600;
		width: 0vw;
	}
	.tl-item-center:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: 50%;
		left: -7.5px;
	}

	/* the first bar */
	.li.complete .status {
		/* border-style: solid;
	border-width: 2px; */
		border-right: 10px solid black;
		border-top: none;
		border-image: linear-gradient(180deg, #0096ff, #94c36b 50%, #fff, #fff) 1;
	}

	/* the first dot */
	.li.complete .status :before {
		background-color: #0096ff;
		border: none;
	}

	.roadmap-cell {
		margin-left: 2rem;
		margin-right: 0rem;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
}

@media screen and (max-height: 500px) {
	.roadmap-subcontainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.roadmap-header {
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		color: #fff;
		font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
		font-size: 3rem;
		font-weight: 800;
		margin: 0;
		margin-top: 2rem;
		max-width: 75vw;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.roadmap-container h3 {
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		color: #fff;
		font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
		font-size: 1.2rem;
		font-weight: 800;
		margin: 0;
		max-width: 75vw;
		text-shadow: none;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.roadmap-container h2 {
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.1rem;
		color: #fff;
		font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
		font-size: 0.8rem;
		font-weight: 800;
		margin: 0;
		max-width: 75vw;
		text-shadow: none;
		margin-bottom: 10px;
		text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
	}

	.roadmap-container p {
		display: block;
		/* text-transform: uppercase; */
		letter-spacing: 0.1rem;
		color: #fff;
		font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
		font-size: 0.8rem;
		font-weight: 500;
		margin: 0;
		max-width: 75vw;
		/* margin-bottom: 10px; */
	}

	.roadmap-body {
		display: flex;
		flex-direction: row;
	}

	.roadmap-cell {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;
		/* width: 300px;
		margin: 20px; */
		/* background-color: #000; */
		margin-left: 1.5rem;
		margin-right: 1.5rem;
		margin-bottom: 2rem;
		margin-top: 2rem;
		width: 220px;
	}

	.roadmap-cell-spc {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;
		/* width: 300px;
		margin: 20px; */
		/* background-color: #000; */
		margin-left: 3.5em;
		margin-right: 3.5rem;
		margin-bottom: 3rem;
		margin-top: 2rem;
		width: 220px;
	}

	.arrow-cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.timeline {
		list-style-type: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 0rem;
	}

	.status {
		padding: 0px 40px;
		display: flex;
		justify-content: center;
		border-top: 10px solid #fff;
		position: relative;
	}

	.tl-item-left {
		font-weight: 600;
		width: 15vw;
	}

	.tl-item-left:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: -17px;
		left: -5%;
	}

	.tl-item-right {
		font-weight: 600;
		width: 15vw;
	}

	.tl-item-right:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: -17px;
		left: 95%;
	}

	.tl-item-center {
		font-weight: 600;
		width: 15vw;
	}
	.tl-item-center:before {
		content: "";
		width: 25px;
		height: 25px;
		color: white;
		background-color: white;
		border-radius: 25px;
		position: absolute;
		top: -17px;
		left: 45%;
	}

	/* the first bar */
	.li.complete .status {
		/* border-style: solid;
		border-width: 2px; */
		border-top: 10px solid black;
		border-image: linear-gradient(90deg, #0096ff, #94c36b 30%, #fff, #fff) 1;
	}

	/* the first dot */
	.li.complete .status :before {
		background-color: #0096ff;
		border: none;
	}

	.roadmap-2container {
		display: flex;
		flex-direction: column;
	}

	.timeline2 {
		display: none;
	}
}
