.form-container {
	background-image: linear-gradient(#f83600, #ffd100);
	min-height: 100vh;
	height: auto;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	object-fit: contain;
}

.form-container a {
	text-decoration: none;
}

.form-subcontainer-1 {
	display: block;
	margin: 4rem;
	width: 400px;
	letter-spacing: 0.1rem;
}

.form-subcontainer-1 h2 {
	display: block;
	font-weight: 900;
	font-size: 2.2rem;
	color: white;
	line-height: 2.5rem;
	margin: 0;
	padding-bottom: 0.5rem;
	list-style-type: none;
	text-transform: uppercase;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* background-image: linear-gradient(60deg, #0600ba, #003cc9);
	background-clip: text;
	color: transparent; */
}

.form-subcontainer-1 ul {
	display: block;
	padding: 0;
	margin: 0;
}

.form-subcontainer-1 li {
	display: block;
	font-weight: 700;
	font-size: 1rem;
	color: white;
	line-height: 2rem;
	text-align: left;
	list-style-type: none;
	padding: 0;
	margin: 0;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.form-subcontainer-2 {
	display: block;
	margin: 4rem;
	width: 400px;
}

.form-subcontainer-2 h2 {
	display: block;
	font-weight: 900;
	font-size: 2.15rem;
	color: white;
	line-height: 2.5rem;
	margin: 0;
	padding-bottom: 3px;
	list-style-type: none;
	text-transform: uppercase;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* background-image: linear-gradient(60deg, #0600ba, #003cc9);
	background-clip: text;
	color: transparent; */
}
.form-subcontainer-2 h4 {
	display: block;
	font-weight: 900;
	font-size: 5.2rem;
	color: white;
	line-height: 2.5rem;
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 18px;
	text-align: left;
	list-style-type: none;
	text-transform: uppercase;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* background-image: linear-gradient(60deg, #0600ba, #003cc9);
	background-clip: text;
	color: transparent; */
}

.form-subcontainer-2 ul {
	display: block;
	padding: 0;
	margin: 0;
}

.form-subcontainer-2 li {
	display: block;
	font-weight: 700;
	font-size: 0.75rem;
	color: white;
	line-height: 1.3rem;
	text-align: justify;
	list-style-type: none;
	text-transform: uppercase;
	padding: 0;
	padding-bottom: 12px;
	padding-top: 0.4rem;
	margin: 0;
	text-shadow: 0 1px 1px rgba(27, 27, 27, 0.08), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.form-header {
	display: block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	color: #fff;
	font-family: "Avenir Next", "Avenir Next Cyr Heavy", sans-serif;
	font-size: 4.96rem;
	font-weight: 800;
	margin: 0;
	max-width: 75vw;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

.form-control {
	padding: 0px !important;
	border: none !important;
}

.waitlist-img {
	display: block;
	width: 400px;
	max-width: 75vw;
	padding-bottom: 1.1rem;
}

.waitlist-text {
	display: block;
	width: 400px;
	max-width: 75vw;
	margin: 0;
	padding-bottom: 1.5rem;
	text-align: justify;
	letter-spacing: 0.1rem;
	font-size: 1.2rem;
	font-weight: 700;
	color: #fff;
	text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
}

input[type="twitter"],
input[type="email"],
input[type="name"],
textarea {
	display: block;
	width: 400px;
	height: 40px;
	padding: 6px 12px;
	font-size: 0.75rem;
	line-height: 1.42857143;
	color: #000;
	background-color: #fff;
	background-image: none;
	border: 0;
	letter-spacing: 0.1rem;
	box-sizing: border-box;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);
	/* margin-bottom: 20px; */
	max-width: 75vw;
	--webkit-border-radius: 0 !important;
	--webkit-appearance: none !important;
	border-radius: 0 !important;
}

input:focus {
	outline: none !important;
}

.form-control {
	margin-bottom: 20px;
}

::placeholder {
	text-transform: uppercase;
	/* padding-top: 0.5rem; */
	/* padding-bottom: 0.5rem; */
	/* font-size: 0.56rem; */
	letter-spacing: 0.1rem;
}

.error {
	color: red;
}

.waitlist-button {
	display: flex;
	justify-content: center;
	border-style: none;
	align-items: center;
	transform: var(--tw-transform);
	position: relative;
	border: 0;
	overflow: hidden;
	outline: none;
	cursor: pointer;
	font-size: 0.75rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	transition: 0.7s;
	margin-bottom: 0.5rem;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
		0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
		0 16px 16px hsl(0deg 0% 0% / 8%);

	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));

	--tw-bg-opacity: 1;
	background-color: rgba(0, 0, 0, var(--tw-bg-opacity));

	width: 400px;
	height: 40px;
	max-width: 75vw;

	z-index: 799;
}

.waitlist-button:hover {
	color: #fff;
	font-size: 0.8rem;
	transition: 0.7s;
}

.waitlist-button:disabled {
	--tw-bg-opacity: 1;
	background-color: rgba(192, 192, 192, var(--tw-bg-opacity));
}

.entry-count {
	font-size: 0.75rem;
	color: #fff;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-weight: 750;
	font-style: italic;
	max-width: 90vw;
}

@media screen and (max-width: 500px) {
	.form-header {
		font-size: 3.7rem;
	}

	.form-subcontainer-2 h2 {
		display: block;
		font-weight: 900;
		font-size: 1.52rem;
		color: white;
		line-height: 2.5rem;
		transform: translateY(1rem);
		margin: 0;
		padding-bottom: 1px;
		list-style-type: none;
		text-transform: uppercase;
		/* text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
		background-image: linear-gradient(60deg, #0600ba, #003cc9);
		background-clip: text;
		color: transparent; */
	}
	.form-subcontainer-2 h4 {
		display: block;
		font-weight: 900;
		font-size: 3.8rem;
		line-height: 2.5rem;
		margin: 0;
		padding-bottom: 18px;
		text-align: left;
		list-style-type: none;
		text-transform: uppercase;
		/* text-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%),
			0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%),
			0 16px 16px hsl(0deg 0% 0% / 8%);
		background-image: linear-gradient(60deg, #0600ba, #003cc9);
		background-clip: text;
		color: transparent; */
	}

	.form-subcontainer-2 ul {
		display: block;
		padding: 0;
		margin: 0;
	}

	.form-subcontainer-2 li {
		display: block;
		font-weight: 700;
		font-size: 0.67rem;
		color: white;
		line-height: 1.2rem;
		text-align: justify;
		list-style-type: none;
		text-transform: uppercase;
		padding: 0;
		padding-bottom: 12px;
		padding-top: 0rem;
		margin: 0;
		/* text-shadow: 0 1px 1px rgba(27, 27, 27, 0.08),
			0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%),
			0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%); */
	}
}
