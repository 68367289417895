/* Make Navbar transparent & part of page. */

.navbar {
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	position: sticky;
	padding-top: 2rem;
	z-index: 1199;
	-webkit-transition: all ease-out 0.25s;
	-moz-transition: all ease-out 0.25s;
	-o-transition: all ease-out 0.25s;
	transition: all ease-out 0.25s;

	/* remove these for effect */
	background-color: #000;
	padding-top: 0.5rem;
	padding-bottom: 0.4rem;
	transition: all ease 0.25s;
}

/* pushes logo to the left */
.navbar .logo-spacer {
	padding-left: 2rem;
	flex-grow: 1;
	z-index: 1299;
}

.navbar .logo-container {
	display: block;
	position: relative;
	z-index: 1299;
}

/* Replace with svg */
/* img tag */
.navbar-logo {
	padding: 0.1rem;
	height: 1.75rem;
	width: auto;
	max-width: 100%;
	display: flex;
	cursor: pointer;
	transform: translateY(-2px);
	z-index: 1299;
	transition: all 0.25s ease;
}

.navbar-container {
	display: flex;
	align-items: center;
	padding-right: 2rem;
	z-index: 1299;
}

.nav-menu {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	--tw-bg-opacity: 1;
	z-index: 1299;
}

.nav-item {
	margin-right: 0.2rem;
	margin-left: 0.2rem;
	z-index: 1299;
}

.nav-links {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	height: 1.75rem;
	position: relative;
	text-decoration: inherit;
	transition-duration: 0.2s;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.7rem;
	/* padding-top: 0.125rem; */
	padding-left: 1rem;
	padding-right: 1rem;
	--tw-bg-opacity: 0.1;
	background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	border-radius: 0.25rem;
	justify-content: center;
	align-items: center;
	z-index: 1299;
	-webkit-transition: all ease-out 0.5s;
	-moz-transition: all ease-out 0.5s;
	-o-transition: all ease-out 0.5s;
	transition: all ease-out 0.5s;
	--tw-bg-opacity: 0; /* remove this for effect */
}

.navbar .blacktext {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	height: 1.75rem;
	position: relative;
	text-decoration: inherit;
	transition-duration: 0.2s;
	--tw-text-opacity: 1;
	color: rgba(0, 0, 0, var(--tw-text-opacity));
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.7rem;
	/* padding-top: 0.125rem; */
	padding-left: 1rem;
	padding-right: 1rem;
	justify-content: center;
	align-items: center;
	z-index: 1299;
	-webkit-transition: all ease-out 0.5s;
	-moz-transition: all ease-out 0.5s;
	-o-transition: all ease-out 0.5s;
	transition: all ease-out 0.5s;
	color: #fff; /* remove this for effect */
}

.nav-links:hover {
	--tw-bg-opacity: 0.3;
	transition: all 0.2s ease-out;
	color: #fff;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

.social-icon {
	stroke: #fff;
	fill: #fff;
	width: 1rem;
	height: 1rem;
	display: block;
	transform: translateY(-1px);
	z-index: 1299;
}
/* 
@media screen and (max-width: 960px) {
	.navbar {
		display: none;
		padding: 0;
	}
} */

@media screen and (max-width: 960px) {
	.navbar {
		display: flex;
		flex-direction: column;
	}

	.navbar-container {
		padding-right: 0;
		/* transform: translateY(-1rem); */
	}
	.navbar .logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1299;
	}
	.navbar .logo-spacer {
		padding-left: 0rem;
	}

	.navbar-logo {
		height: 3rem;
		margin: 0rem;
		margin-top: 0.5rem;
	}
}
